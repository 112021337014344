import React from 'react';
import PropTypes from 'prop-types';
import {observer, PropTypes as MobXPropTypes} from 'mobx-react';

import {
    COMMUNITIES,
    FACTSHEETS,
    HOSPITALS,
    USERPROFILES,
    SPECIALTIES,
    LOCALITIES,
    ORGANISATION_PROFILES,
    PRACTICE_GROUPS,
    PROMOTED_PROFILES,
    PROVIDER_TYPES,
    PROVIDER_TYPES_IN_LOCALITIES,
    SPECIAL_INTERESTS,
    SPECIALTIES_IN_LOCALITIES,
    SPECIALTIES_IN_HOSPITALS,
    SPECIAL_INTERESTS_IN_LOCALITIES,
    THREADS,
    TELEHEALTH_SPECIALTIES,
    STATE,
} from 'base/search.js';
import autobind from 'common/decorators/autobind.js';
import Avatar from 'core/components/Avatar.js';
import Link from 'core/components/Link.js';
import {TELEHEALTH} from 'core/constants.js';
import {SearchStoreContext} from 'core/stores/RootStore.js';

export default
@observer
class SearchResult extends React.Component {
    static contextType = SearchStoreContext;

    static propTypes = {
        result: PropTypes.shape({
            avatar: PropTypes.string,
            extra: PropTypes.shape({
                specialties: MobXPropTypes.arrayOrObservableArrayOf(
                    PropTypes.string,
                ),
                initials: PropTypes.string,
                healthFundRelationshipFromPassedHealthFund: PropTypes.string,
            }),
            id: PropTypes.any.isRequired,
            objectType: PropTypes.oneOf([
                COMMUNITIES,
                FACTSHEETS,
                HOSPITALS,
                LOCALITIES,
                PROMOTED_PROFILES,
                PROVIDER_TYPES_IN_LOCALITIES,
                USERPROFILES,
                SPECIALTIES,
                SPECIAL_INTERESTS,
                SPECIALTIES_IN_LOCALITIES,
                SPECIALTIES_IN_HOSPITALS,
                TELEHEALTH_SPECIALTIES,
                PRACTICE_GROUPS,
                PROVIDER_TYPES,
                SPECIAL_INTERESTS_IN_LOCALITIES,
                THREADS,
                ORGANISATION_PROFILES,
                STATE,
            ]).isRequired,
            text: PropTypes.string.isRequired,
            url: PropTypes.string,
        }).isRequired,
        searchQuery: PropTypes.string.isRequired,
        searchType: PropTypes.string,
    };

    sendGAPromotedProfilesImpressionEvent(id, text) {
        if (!window.trackedPromotedProfiles) {
            window.trackedPromotedProfiles = {};
        }
        if (!window.trackedPromotedProfiles[id]) {
            const {
                flattenedResults,
                rootStore: {
                    paramStore: {isClient},
                },
            } = this.context;
            const {searchQuery} = this.props;

            window.trackedPromotedProfiles[id] = true;
        }
    }

    @autobind
    handleHighlight(text) {
        // Multiple * characters break the highlighting regex so just strip them out
        const trimmedSearchString = this.props.searchQuery
            .trim()
            .replace('*', '');
        if (trimmedSearchString) {
            const textIncludesAcceptedPunctuation =
                text.includes('-') || text.includes("'") || text.includes('/');
            const words = trimmedSearchString
                .split(
                    textIncludesAcceptedPunctuation
                        ? /[\s+()]/g
                        : /([ \-'\/,])/g,
                )
                .reduce((acc, word) => {
                    // restrict to alphanumeric characters except hyphens and apostrophes
                    const newWord = word.replace(
                        textIncludesAcceptedPunctuation
                            ? /[^a-zA-Z0-9'-\/]/g
                            : /[^a-zA-Z0-9]/g,
                        '',
                    );
                    // only highlight words more than one character long
                    if (newWord && newWord.length > 1) {
                        return [...acc, newWord];
                    }
                    return acc;
                }, []);
            const pattern = new RegExp(`\\b(${words.join('|')})`, 'gi');
            return text.replace(pattern, '<span class="highlight">$&</span>');
        }
        return text;
    }

    @autobind
    handleLocalityHighlight(text) {
        const trimmedSearchString = this.props.searchQuery.trim();
        if (trimmedSearchString) {
            const words = trimmedSearchString
                .split(/\s+/)
                .reduce((acc, word) => {
                    // restrict to alphanumeric characters
                    const newWord = word.replace(/[^a-zA-Z0-9 ]/g, '');
                    if (newWord) {
                        return [...acc, newWord];
                    }
                    return acc;
                }, []);
            const pattern = new RegExp(`(${words.join('|')})`, 'gi');
            return text.replace(pattern, '<span class="highlight">$&</span>');
        }
        return text;
    }

    get badges() {
        const {extra} = this.props.result;
        const {
            isMobile,
            rootStore: {
                healthFundStore: {isHealthFundBookingsEnabled},
                paramStore: {skin},
            },
        } = this.context;

        const badges = [];

        if (extra.offersTelehealth) {
            badges.push(TELEHEALTH);
        }
        if (extra.offersHicaps) {
            badges.push('HICAPS');
        }

        const isSkinCustomBookingHidden =
            !skin || extra.nonCustomBookingsEnabled;
        if (
            extra.bookingsEnabled &&
            isHealthFundBookingsEnabled &&
            isSkinCustomBookingHidden
        ) {
            if (isMobile) {
                badges.unshift('Book online');
            } else {
                badges.push('Book online');
            }
        }

        return badges;
    }

    renderBadges() {
        const {isMobile} = this.context;
        if (this.badges.length > 0) {
            return (
                <ul className="badges">
                    {this.badges.map((badge, index) => {
                        if (isMobile && index > 0) {
                            return null;
                        }
                        return (
                            <React.Fragment key={`${badge}-${index}`}>
                                <li>{badge}</li>
                                {isMobile && this.badges.length > 1 && (
                                    <li>{`+${this.badges.length - 1}`}</li>
                                )}
                            </React.Fragment>
                        );
                    })}
                </ul>
            );
        }
    }

    get iconName() {
        const {objectType} = this.props.result;
        switch (objectType) {
            case COMMUNITIES:
                return 'notForProfits';
            case FACTSHEETS:
                return 'factsheets';
            case HOSPITALS:
            case PROVIDER_TYPES:
            case SPECIALTIES_IN_HOSPITALS:
                return 'hospital';
            case ORGANISATION_PROFILES:
                return 'corporateHealth';
            case PRACTICE_GROUPS:
            case SPECIALTIES_IN_LOCALITIES:
            case LOCALITIES:
                return 'mapPin';
            case SPECIAL_INTERESTS:
                return 'search2';
            case SPECIALTIES:
                return 'generalPractitioners';
            case THREADS:
                return 'answers';
        }
        return '';
    }

    get labelName() {
        const {objectType} = this.props.result;
        switch (objectType) {
            case COMMUNITIES:
                return 'Community';
            case FACTSHEETS:
                return 'Fact Sheet';
            case ORGANISATION_PROFILES:
                return 'Organisation';
            case THREADS:
                return 'Q&A';
        }
        return '';
    }

    renderResult() {
        const {avatar, extra, objectType, text} = this.props.result;
        let href = this.props.result.url || null;
        const {
            clickResult,
            hrefForHealthFundWidget,
            isHealthFundWidget,
            isMobile,
        } = this.context;
        if (isHealthFundWidget) {
            href = hrefForHealthFundWidget(href);
        }
        switch (objectType) {
            case PROMOTED_PROFILES:
                const {
                    id,
                    headline,
                    initials,
                    locality,
                    searchAvatar,
                    specialty,
                } = this.props.result;
                this.sendGAPromotedProfilesImpressionEvent(id, headline);
                return (
                    <Link
                        actionWithEvent={(evt) =>
                            clickResult(this.props.result, evt)
                        }
                        customClass="result-body profile"
                        href={href}
                    >
                        <Avatar avatar={searchAvatar} initials={initials} />
                        <div>
                            <strong>{headline}</strong>
                            <span>{specialty}</span>
                            <span>{locality}</span>
                        </div>
                    </Link>
                );
            case USERPROFILES:
                return (
                    <Link
                        actionWithEvent={(evt) =>
                            clickResult(this.props.result, evt)
                        }
                        customClass="result-body profile"
                        href={href}
                    >
                        <Avatar avatar={avatar} initials={extra.initials} />
                        <div>
                            <strong
                                dangerouslySetInnerHTML={{
                                    __html: this.handleHighlight(text),
                                }}
                            />
                            {isMobile && this.renderBadges()}
                            {extra && (
                                <>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: this.handleHighlight(
                                                extra.specialties.join(', '),
                                            ),
                                        }}
                                    />
                                    {extra.location && (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: this.handleHighlight(
                                                    extra.location,
                                                ),
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                        {!isMobile && this.renderBadges()}
                    </Link>
                );
            case TELEHEALTH_SPECIALTIES:
                return (
                    <Link
                        actionWithEvent={(evt) =>
                            clickResult(this.props.result, evt)
                        }
                        customClass="result-body"
                        href={href}
                        icon="generalPractitioners"
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: this.handleHighlight(text),
                            }}
                        />
                    </Link>
                );
            case SPECIALTIES:
            case SPECIAL_INTERESTS:
                // No href on this result type as we don't want it taking to another page
                return (
                    <Link
                        action={() => clickResult(this.props.result)}
                        customClass="result-body"
                        icon={this.iconName}
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: this.handleHighlight(text),
                            }}
                        />
                    </Link>
                );
            case SPECIALTIES_IN_LOCALITIES:
                return (
                    <Link
                        actionWithEvent={(evt) =>
                            clickResult(this.props.result, evt)
                        }
                        customClass="result-body"
                        href={href}
                        icon={this.iconName}
                    >
                        <span>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: this.handleHighlight(
                                        extra.specialty,
                                    ),
                                }}
                            />{' '}
                            in{' '}
                            <strong
                                dangerouslySetInnerHTML={{
                                    __html: this.handleHighlight(
                                        extra.locality,
                                    ),
                                }}
                            />
                        </span>
                    </Link>
                );
            case SPECIALTIES_IN_HOSPITALS:
                return (
                    <Link
                        actionWithEvent={(evt) =>
                            clickResult(this.props.result, evt)
                        }
                        customClass="result-body"
                        href={href}
                        icon={this.iconName}
                    >
                        <span>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: this.handleHighlight(
                                        extra.specialty.displayText,
                                    ),
                                }}
                            />{' '}
                            in{' '}
                            <strong
                                dangerouslySetInnerHTML={{
                                    __html: this.handleHighlight(
                                        extra.hospital,
                                    ),
                                }}
                            />
                        </span>
                    </Link>
                );
            case PROVIDER_TYPES_IN_LOCALITIES:
                return (
                    <Link
                        actionWithEvent={(evt) =>
                            clickResult(this.props.result, evt)
                        }
                        customClass="result-body"
                        href={href}
                        icon={this.iconName}
                    >
                        <span>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: this.handleHighlight(text),
                                }}
                            />
                        </span>
                    </Link>
                );
            case SPECIAL_INTERESTS_IN_LOCALITIES:
                return (
                    <Link
                        actionWithEvent={(evt) =>
                            clickResult(this.props.result, evt)
                        }
                        customClass="result-body"
                        href={href}
                        icon="mapPin"
                    >
                        <span>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: this.handleHighlight(
                                        extra.interest,
                                    ),
                                }}
                            />{' '}
                            in{' '}
                            <strong
                                dangerouslySetInnerHTML={{
                                    __html: this.handleHighlight(
                                        extra.locality,
                                    ),
                                }}
                            />
                        </span>
                    </Link>
                );
            case COMMUNITIES:
            case FACTSHEETS:
            case THREADS:
            case ORGANISATION_PROFILES:
                return (
                    <Link
                        actionWithEvent={(evt) =>
                            clickResult(this.props.result, evt)
                        }
                        customClass="result-body"
                        href={href}
                        icon={this.iconName}
                    >
                        <span>
                            <span className="search-type">
                                {this.labelName}
                                {':'}
                            </span>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: this.handleHighlight(text),
                                }}
                            />
                        </span>
                    </Link>
                );
            case HOSPITALS:
                return (
                    <Link
                        actionWithEvent={(evt) =>
                            clickResult(this.props.result, evt)
                        }
                        customClass="result-body"
                        icon={this.iconName}
                    >
                        <div>
                            <strong
                                dangerouslySetInnerHTML={{
                                    __html: this.handleHighlight(text),
                                }}
                            />
                            {extra &&
                                extra.healthFundRelationshipFromPassedHealthFund && (
                                    <div className="hfr-search-display">
                                        {
                                            extra.healthFundRelationshipFromPassedHealthFund
                                        }
                                    </div>
                                )}
                        </div>
                    </Link>
                );
            case PROVIDER_TYPES:
                return (
                    <Link
                        action={() => clickResult(this.props.result)}
                        customClass="result-body"
                        icon={this.iconName}
                    >
                        <strong
                            dangerouslySetInnerHTML={{
                                __html: this.handleHighlight(text),
                            }}
                        />
                    </Link>
                );
            case PRACTICE_GROUPS:
                return (
                    <Link
                        actionWithEvent={(evt) =>
                            clickResult(this.props.result, evt)
                        }
                        customClass="result-body practice-result"
                        href={href}
                        icon={this.iconName}
                    >
                        <div>
                            <strong
                                dangerouslySetInnerHTML={{
                                    __html: this.handleHighlight(text),
                                }}
                            />
                            {extra && extra.location && (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: this.handleHighlight(
                                            extra.location,
                                        ),
                                    }}
                                />
                            )}
                        </div>
                    </Link>
                );
            case LOCALITIES:
            case STATE:
                return (
                    <Link
                        action={() => clickResult(this.props.result)}
                        customClass="result-body"
                        icon={this.iconName}
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: this.handleLocalityHighlight(text),
                            }}
                        />
                    </Link>
                );
            default:
                return null;
        }
    }

    render() {
        const {id, objectType, text} = this.props.result;

        return (
            <li
                className={`${
                    objectType === USERPROFILES ||
                    objectType === PROMOTED_PROFILES
                        ? 'profile-result'
                        : ''
                }`.trim()}
                data-test-id={objectType}
                id={id}
                key={`${text}-${id}`}
            >
                {this.renderResult()}
            </li>
        );
    }
}
