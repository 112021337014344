import React from 'react';
import {PropTypes} from 'prop-types';
import {observer} from 'mobx-react';

import Link from 'core/components/Link.js';
import {SearchStoreContext} from 'core/stores/RootStore.js';
import {POPULAR_SPECIALTY_RESULT_LINK} from 'core/constants';

export default
@observer
class PopularSpecialty extends React.Component {
    static contextType = SearchStoreContext;

    static propTypes = {
        specialty: PropTypes.object.isRequired,
    };

    static defaultProps = {
        specialty: {},
    };

    constructor(props) {
        super(props);
    }

    changeTextName(specialty, newName) {
        specialty.specialty.text = newName;
        specialty.specialty.sortText = newName;
        return specialty;
    }

    render() {
        const {specialty} = this.props;
        const {clickResult} = this.context;
        const {
            isProUser,
            isReferrals,
            isClient,
        } = this.context.rootStore.paramStore;
        // Hack to change the display name when user is logged on Site
        // we need to display the professional name
        const name =
            isProUser || isReferrals || isClient
                ? specialty.professionalName
                : specialty.name;
        const parsedSpecialty = this.changeTextName(specialty, name);
        return (
            <li data-test-id="popular_specialties">
                <Link
                    action={() => clickResult(parsedSpecialty.specialty)}
                    customClass="result-body"
                    icon="generalPractitioners"
                    gaSelector={POPULAR_SPECIALTY_RESULT_LINK}
                >
                    {name}
                </Link>
            </li>
        );
    }
}
