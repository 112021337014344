import PropTypes from 'prop-types';
import React from 'react';

import MissingResultButton from 'search/components/MissingResultButton.js';
import SearchResult from 'search/components/SearchResult.js';
import {DEFAULT_FILTER_TYPES, USERPROFILES} from 'base/search.js';
import {SEARCH_STATE} from 'core/constants.js';
import Icon from 'core/components/Icon.js';

export default class SearchResults extends React.Component {
    static propTypes = {
        displayFacets: PropTypes.bool,
        facets: PropTypes.array,
        results: PropTypes.object.isRequired,
        searchQuery: PropTypes.string.isRequired,
        searchType: PropTypes.string.isRequired,
        showMissingResultButton: PropTypes.bool,
    };

    static defaultProps = {
        facets: [],
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    templateOpts(facetType) {
        return DEFAULT_FILTER_TYPES[facetType];
    }

    renderFacetIcon(facetType) {
        const {colour, icon} = this.templateOpts(facetType);
        return <i className={`fa ${icon} ${colour}`}></i>;
    }

    renderShowMore(facetType) {
        const isProfileFacet = facetType === USERPROFILES;
        return (
            <li>
                <div
                    className={`result-body overflow-indicator${
                        isProfileFacet ? ' profile' : ''
                    }`}
                >
                    <Icon name="overflow" />
                    <div>
                        {
                            'More results are available. Please continue to refine your search.'
                        }
                    </div>
                </div>
            </li>
        );
    }

    renderResults() {
        return Object.entries(this.props.results).map(
            ([facetType, results]) => {
                // eslint-disable-next-line max-len
                const templateOpts = this.templateOpts(facetType);
                if (!templateOpts || !results.length) {
                    // prevent rendering of empty section
                    return null;
                }

                const resIds = results.map((res) => res.id).join('');

                let displayShowMoreLink = false;
                // eslint-disable-next-line max-len
                const displayFacet = this.props.facets.find(
                    (facet) => facet.type === facetType,
                );
                if (displayFacet) {
                    displayShowMoreLink = displayFacet.showMore;
                }

                return (
                    <React.Fragment key={`${facetType}-${resIds}`}>
                        {this.props.displayFacets && templateOpts?.title && (
                            <li className="result-title">
                                {this.renderFacetIcon(facetType)}
                                <h4>{templateOpts.title}</h4>
                            </li>
                        )}
                        {results.map((result) => (
                            <SearchResult
                                key={`${result.text}-${result.id}`}
                                result={result}
                                searchQuery={this.props.searchQuery}
                                searchType={this.props.searchType}
                            />
                        ))}
                        {displayShowMoreLink && this.renderShowMore(facetType)}
                    </React.Fragment>
                );
            },
        );
    }

    render() {
        const {
            results,
            searchQuery,
            searchType,
            showMissingResultButton,
        } = this.props;
        let hasResults = !!Object.values(results).length;
        if (searchType === SEARCH_STATE) {
            hasResults = !!results?.results?.length;
        }

        return (
            <ul className="search-results">
                {hasResults ? (
                    this.renderResults()
                ) : (
                    <li className="no-results">
                        <p>{`No results for "${searchQuery}"`}</p>
                    </li>
                )}
                {showMissingResultButton && <MissingResultButton />}
            </ul>
        );
    }
}
