import {PropTypes} from 'prop-types';
import React from 'react';

import PopularSpecialty from 'search/components/PopularSpecialty.js';

export default class PopularSpecialties extends React.Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        results: PropTypes.array,
    };

    static defaultProps = {
        results: [],
    };

    renderPopularSpecialties(results) {
        return (
            <>
                {results.map((specialty) => (
                    <PopularSpecialty
                        key={`${specialty.name}-${specialty.specialtyId}`}
                        specialty={specialty}
                    />
                ))}
            </>
        );
    }

    render() {
        const {results} = this.props;
        return (
            <>
                {!!results.length && (
                    <ul className="search-results">
                        <li className="result-title">
                            <i className="fa fa-stethoscope yellow"></i>
                            <h4>Popular Specialties</h4>
                        </li>
                        {this.renderPopularSpecialties(results)}
                    </ul>
                )}
            </>
        );
    }
}
