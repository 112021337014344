import PropTypes from 'prop-types';
import React from 'react';
import {observer} from 'mobx-react';

import autobind from 'common/decorators/autobind.js';
import Button from 'core/components/Button.js';
import Icon from 'core/components/Icon.js';
import {SEARCH_BTN} from 'core/constants.js';

export default
@observer
class SearchInput extends React.Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        completeFormDisplayed: PropTypes.bool,
        customClass: PropTypes.string,
        expanded: PropTypes.bool,
        handleSubmit: PropTypes.func,
        hidePrefix: PropTypes.bool,
        id: PropTypes.string,
        inputLabel: PropTypes.string,
        inputRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({current: PropTypes.instanceOf(Element)}),
        ]),
        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
        placeholder: PropTypes.string.isRequired,
        renderIntegratedTitle: PropTypes.bool,
        searchType: PropTypes.string,
        value: PropTypes.string.isRequired,
    };

    static defaultProps = {
        customClass: '',
        hidePrefix: false,
        inputLabel: 'Search',
        onBlur: () => {},
        onFocus: () => {},
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    @autobind
    onChange(event) {
        this.props.onChange(event.target.value);
    }

    @autobind
    onClear() {
        this.props.onChange('');
        this.props.inputRef?.current?.focus();
    }

    render() {
        const {
            autoFocus,
            completeFormDisplayed,
            customClass,
            expanded,
            handleSubmit,
            hidePrefix,
            id,
            inputLabel,
            inputRef,
            onBlur,
            onFocus,
            placeholder,
            renderIntegratedTitle,
            value,
        } = this.props;
        return (
            <fieldset className={customClass}>
                {renderIntegratedTitle && (
                    <div>
                        <div className="title">
                            <Icon name="mark" />
                            <strong>{'HealthShare Referrals'}</strong>
                            <em>
                                {
                                    'Search by specialty, name or special interest'
                                }
                            </em>
                        </div>
                    </div>
                )}
                <input
                    aria-haspopup={true}
                    aria-label={inputLabel}
                    autoComplete="off"
                    autoCorrect="off"
                    autoFocus={autoFocus}
                    className={`${completeFormDisplayed ? 'flat-base' : ''} ${
                        value.length > 0 ? 'placeholder-hidden' : ''
                    } ${expanded ? 'expanded' : ''}`.trim()}
                    id={id}
                    maxLength={100}
                    name={inputLabel}
                    onBlur={onBlur}
                    onChange={this.onChange}
                    onFocus={onFocus}
                    placeholder=""
                    ref={inputRef}
                    type="text"
                    value={value}
                />
                {hidePrefix && (
                    <label htmlFor={inputLabel}>
                        {inputLabel}
                        <em>{placeholder}</em>
                    </label>
                )}
                {!hidePrefix && (
                    <label htmlFor={inputLabel}>
                        {inputLabel}
                        <em>{`${inputLabel ? ' — ' : ''}${placeholder}`}</em>
                    </label>
                )}
                {handleSubmit && (
                    <Button
                        action={handleSubmit}
                        gaSelector={SEARCH_BTN}
                        isPreventDefault={true}
                        text={'Search'}
                        type="submit"
                        customClass="cta"
                    />
                )}
                {value?.length > 0 && (
                    <Button
                        action={this.onClear}
                        customClass="reset-field"
                        icon="close"
                        text=""
                        type="reset"
                    />
                )}
            </fieldset>
        );
    }
}
