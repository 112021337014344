import PropTypes from 'prop-types';
import React from 'react';
import {observer} from 'mobx-react';
import {toJS} from 'mobx';

import events from 'events.js';

import {MOBILE_MAX_SCREEN_SIZE} from 'base/search.js';
import autobind from 'common/decorators/autobind.js';
import PopularSpecialties from 'search/components/PopularSpecialties.js';
import {SearchStoreContext} from 'core/stores/RootStore.js';
import {getPopularSpecialties} from 'search/utils.js';
import SearchInput from 'search/components/SearchInput.js';
import SearchResults from 'search/components/SearchResults.js';
import {sessionStorageGetItem, sessionStorageSetItem} from 'core/utils.js';
import {
    FEATURE_INTEGRATED_PROFILE,
    SEARCH_LOCALITY,
    SEARCH_QUERY,
    SEARCH_STATE,
    FIND_A_PROVIDER,
    SEARCH_BTN,
} from 'core/constants.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';
import Button from 'core/components/Button.js';
import Icon from 'core/components/Icon.js';

export default
@observer
class SearchForm extends React.Component {
    static contextType = SearchStoreContext;

    static propTypes = {
        interest: PropTypes.object,
        isClientReferralsPage: PropTypes.bool,
        specialty: PropTypes.object,
    };

    static defaultProps = {
        isClientReferralsPage: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            searchBounced: false,
            autoFocus: false,
            popularSpecialties: [],
        };
        this.specialtyInputRef = React.createRef();
        this.localityInputRef = React.createRef();
        this.searchFormRef = React.createRef();
        this.bounceTimer = null;
        this.inactiveTimer = null;
    }

    getMainElement() {
        return document.getElementById('directory');
    }

    getMainHospitalElement() {
        return document.getElementById('hospitalDirectory');
    }

    prepopulateSearch() {
        const mainElement = this.getMainElement();
        const mainHospitalElement = this.getMainHospitalElement();

        const isReferralsPage = window.location.href.includes('/referrals');
        const query = sessionStorageGetItem(SEARCH_QUERY);
        if (query && !isReferralsPage) {
            this.context.updateStore({
                searchQuery: query,
                resultQueryToggled: true,
            });
        }
        const {
            rootStore: {
                paramStore: {isProUser, isReferrals},
            },
        } = this.context;
        if (mainElement) {
            const data = JSON.parse(mainElement.dataset.directory);
            if (data.specialty?.professional_name) {
                this.context.updateStore({
                    specialty: {
                        ...data.specialty,
                        text:
                            isReferrals || isProUser
                                ? data.specialty.professional_name
                                : data.specialty.name,
                    },
                });
            }
            if (data.interest?.name) {
                this.context.updateStore({
                    interest: {
                        ...data.interest,
                        text: `${data.interest.specialty.professional_name} - ${data.interest.name}`,
                    },
                });
            }
        }

        if (mainHospitalElement) {
            this.context.updateStore({
                resultQueryToggled: true,
                providerType: {id: 1, text: 'Hospital'}, // TODO make this dynamic when more provider types added
            });
        }
    }

    handleReferralsLandingPage() {
        const {interest, specialty} = this.props;
        if (specialty?.id) {
            this.context.updateStore({
                specialty,
            });

            if (interest?.id) {
                this.context.updateStore({
                    interest: {
                        id: interest.id,
                        text: `${specialty.text} - ${interest.text}`,
                    },
                });
                this.setState({
                    autoFocus: true,
                });
            }
        }
    }

    async loadPopularSpecialties() {
        const popularSpecialtiesList = await getPopularSpecialties(
            this.context.rootStore.paramStore.showParam,
        );
        this.setState({
            popularSpecialties: Object.values(
                snakeToCamelObjectKeys(popularSpecialtiesList),
            ),
        });
    }

    async componentDidMount() {
        events.listen(
            document.body,
            'keydown',
            this.context.handleKeyboardNavigation,
        );
        events.listen(document.body, 'mousedown', this.handleOutsideClick);
        events.listen(document, 'scroll', this.handleScroll);
        events.listen(
            window,
            ['resize', 'orientationchange'],
            this.onWindowResize,
        );
        this.onWindowResize();
        events.listen(
            document,
            FEATURE_INTEGRATED_PROFILE,
            this.handleFeatureEvent,
        );

        this.context.updateStore({
            isClientReferralsPage: this.props.isClientReferralsPage,
        });

        const isHospitalEnabled =
            document.body.dataset.hospitalFfEnabled === 'True';
        const telehealth = document.body.dataset.telehealth === 'True';
        this.context.updateStore({
            isHospitalEnabled,
            telehealth,
            isHealthFundWidget: this.props.isHealthWidget,
        });
        this.context.fetchSavedLocality();
        this.prepopulateSearch();
        await this.loadPopularSpecialties();
        if (this.props.isReferralsLandingPage) {
            this.handleReferralsLandingPage();
        }

        // prevent location field being autofocused if visible on page load
        setTimeout(() => {
            this.setState({
                autoFocus: true,
            });
        }, 10);
    }

    componentWillUnmount() {
        events.unlisten(
            document.body,
            'keydown',
            this.context.handleKeyboardNavigation,
        );
        events.unlisten(document.body, 'mousedown', this.handleOutsideClick);
        events.unlisten(document, 'scroll', this.handleScroll);
        events.unlisten(
            window,
            ['resize', 'orientationchange'],
            this.onWindowResize,
        );
        events.unlisten(
            document,
            FEATURE_INTEGRATED_PROFILE,
            this.handleFeatureEvent,
        );
    }

    @autobind
    onWindowResize() {
        const isMobile = window.matchMedia(
            `(max-width: ${MOBILE_MAX_SCREEN_SIZE})`,
        ).matches;
        this.context.updateStore({
            isMobile,
        });
    }

    @autobind
    handleOutsideClick(event) {
        if (
            this.searchFormRef.current &&
            !this.searchFormRef.current.contains(event.target)
        ) {
            this.context.updateStore({displayResults: false, hasFocus: false});
            this.context.searchLocalityStore.updateStore({
                showResults: false,
            });
            this.context.stateLocalityStore.updateStore({
                showResults: false,
            });

            // don't hide search fields if a result is clicked
            if (event.target.className !== 'result-body') {
                this.context.updateStore({
                    searchFieldsVisible: false,
                });
            }
        }
    }

    @autobind
    handleScroll() {
        // searchBounced cannot revert to false from scroll event when it is true
        if (!this.state.searchBounced) {
            this.setState({
                searchBounced: this.context.displayResults,
            });
        }
        this.resetInactiveTimer();
    }

    get isHomePage() {
        return window.location.pathname === '/';
    }

    get searchFieldsVisible() {
        if (this.context.rootStore.paramStore?.isClient) {
            return true;
        }
        if (
            this.context.isMobile &&
            !this.isHomePage &&
            !this.props.isClientReferralsPage
        ) {
            return this.context.searchFieldsVisible;
        }
        return true;
    }

    @autobind
    toggleSearchFieldsVisibility() {
        this.context.updateStore({
            searchFieldsVisible: !this.context.searchFieldsVisible,
        });
        this.setState(
            () => ({
                autoFocus: false,
            }),
            () => {
                if (this.context.searchFieldsVisible) {
                    // focus specialty field if only field
                    if (!this.context.displayCompleteSearchForm) {
                        this.specialtyInputRef?.current.focus();
                        this.specialtyInputRef?.current.select();
                    } else {
                        this.localityInputRef?.current.focus();
                        this.localityInputRef?.current.select();
                    }
                }
                setTimeout(() => {
                    this.setState({
                        autoFocus: true,
                    });
                }, 0);
            },
        );
    }

    @autobind
    handleSpecialtyInputBlur() {
        setTimeout(() => {
            this.context.updateStore({hasFocus: false});
            if (document.activeElement) {
                document.activeElement.blur();
            }
        }, 200);
    }

    @autobind
    handleSpecialtyInputChange(value) {
        sessionStorageSetItem(SEARCH_QUERY, value);
        this.resetBounceTimer();
        this.resetInactiveTimer();
        this.sendBounceEvent('bounce');

        if (value === '') {
            this.context.resetSearch();
        }
        this.context.updateStore({
            searchQuery: value,
            specialty: null,
            interest: null,
        });
    }

    @autobind
    handleLocalityInputChange(value) {
        this.context.searchLocalityStore.updateStore({
            value,
            selected: {},
            showResults: !!value.length,
        });
    }

    @autobind
    handleStateInputChange(value) {
        this.context.stateLocalityStore.updateStore({
            value,
            selected: {},
        });
    }

    @autobind
    displaySpecialtyResults(event) {
        // Wrap in try catch because we dont want to prevent the integration from running
        try {
            if (window.LogRocket && !window.logRocketShown) {
                window.logRocketShown = true;
                if (
                    location.hostname === 'www.healthshare.com.au' &&
                    window.location.pathname.includes('/referrals')
                ) {
                    window.LogRocket.init('kc3htu/healthsharecomau');
                }
            }
        } catch {}

        const {flattenedResults, resetCalled, searchQuery} = this.context;
        this.context.updateStore({
            hasFocus: true,
        });
        // Call the api on initial load if no results are yet populated.
        if (!resetCalled && !flattenedResults.length && searchQuery === '') {
            this.context.updateStore({
                searchQuery: event.target.value,
                specialty: null,
                interest: null,
            });
            return;
        }
        if (this.context.searchQuery.length > 1) {
            this.context.updateStore({displayResults: true});
        }
        this.context.searchLocalityStore.updateStore({
            showResults: false,
        });
        this.context.stateLocalityStore.updateStore({
            showResults: false,
        });
    }

    @autobind
    displayLocalityResults() {
        const {value, selected} = this.context.searchLocalityStore;
        if (value.length > 1 || selected?.name) {
            setTimeout(() => {
                this.context.searchLocalityStore.updateStore({
                    showResults: true,
                });
            }, 0);
        }
        this.context.updateStore({displayResults: false});
    }

    @autobind
    displayStateResults() {
        setTimeout(() => {
            this.context.stateLocalityStore.updateStore({
                showResults: true,
            });
        }, 0);
    }

    get searchFormClassName() {
        return `search-form ${
            this.context.isInitialClientReferralsState ? 'initial' : ''
        }`.trim();
    }

    @autobind
    resetBounceTimer() {
        clearTimeout(this.bounceTimer);
        if (!this.state.searchBounced) {
            this.bounceTimer = setTimeout(() => {
                this.setState({
                    searchBounced: true,
                });
            }, 5000);
        }
    }

    @autobind
    resetInactiveTimer() {
        clearTimeout(this.inactiveTimer);
        this.inactiveTimer = setTimeout(() => {
            this.sendBounceEvent('inactive');
        }, 30000);
    }

    @autobind
    sendBounceEvent(action) {
        if (this.state.searchBounced) {
            this.setState({
                searchBounced: false,
            });
        }
    }

    renderLocalityFields() {
        const {
            isMobile,
            searchLocalityStore,
            stateLocalityStore,
            showStateLocalities,
            shouldRedirect,
            searchRedirect,
        } = this.context;
        if (showStateLocalities) {
            return (
                <>
                    <SearchInput
                        autoFocus={this.state.autoFocus}
                        customClass="search-locality"
                        expanded={
                            !stateLocalityStore.hideResults &&
                            stateLocalityStore.showResults
                        }
                        handleSubmit={shouldRedirect ? searchRedirect : null}
                        id={'location'}
                        inputLabel={'Location'}
                        inputRef={this.localityInputRef}
                        onChange={this.handleStateInputChange}
                        onFocus={this.displayStateResults}
                        placeholder={'State'}
                        searchType={SEARCH_STATE}
                        value={
                            stateLocalityStore.selected?.name ||
                            stateLocalityStore.value ||
                            ''
                        }
                    />
                    {!stateLocalityStore.hideResults &&
                        stateLocalityStore.showResults && (
                            <SearchResults
                                results={toJS(
                                    stateLocalityStore.filteredStates,
                                )}
                                searchQuery={
                                    stateLocalityStore.selected?.name ||
                                    stateLocalityStore.value
                                }
                                searchType={SEARCH_STATE}
                            />
                        )}
                </>
            );
        }

        return (
            <>
                <SearchInput
                    autoFocus={this.state.autoFocus}
                    customClass="search-locality"
                    expanded={searchLocalityStore.showResults}
                    handleSubmit={shouldRedirect ? searchRedirect : null}
                    hidePrefix={true}
                    id={'location'}
                    inputLabel={isMobile ? 'Location' : ''}
                    inputRef={this.localityInputRef}
                    onChange={this.handleLocalityInputChange}
                    onFocus={this.displayLocalityResults}
                    placeholder={'Search by suburb, postcode or hospital name'}
                    searchType={SEARCH_LOCALITY}
                    value={
                        searchLocalityStore.selected?.name ||
                        searchLocalityStore.value ||
                        ''
                    }
                />
                {searchLocalityStore.showResults && (
                    <SearchResults
                        results={toJS(searchLocalityStore.suggestions)}
                        searchQuery={
                            searchLocalityStore.selected?.name ||
                            searchLocalityStore.value
                        }
                        searchType={SEARCH_LOCALITY}
                    />
                )}
            </>
        );
    }

    render() {
        const {
            facets,
            results,
            displayCompleteSearchForm,
            displayResults,
            isMobile,
            searchQuery,
            promotedLocality,
            promotedProfiles,
            promotedSpecialty,
            rootStore: {paramStore},
        } = this.context;
        const {searchPlaceholder, isHealthWidget} = this.props;
        return (
            <form
                autoCapitalize="false"
                autoComplete="false"
                autoCorrect="false"
                className={this.searchFormClassName}
                ref={this.searchFormRef}
                spellCheck="false"
            >
                {this.searchFieldsVisible || isHealthWidget ? (
                    <>
                        <SearchInput
                            autoFocus={
                                isMobile &&
                                !paramStore?.isClient &&
                                !searchQuery.length
                            }
                            completeFormDisplayed={displayCompleteSearchForm}
                            expanded={
                                displayResults && searchQuery?.length > 0
                            }
                            id={'search'}
                            inputLabel={
                                !isMobile && searchPlaceholder ? '' : 'Search'
                            }
                            inputRef={this.specialtyInputRef}
                            onBlur={this.handleSpecialtyInputBlur}
                            onChange={this.handleSpecialtyInputChange}
                            onFocus={this.displaySpecialtyResults}
                            placeholder={
                                searchPlaceholder ||
                                'e.g. "John Smith”, “Hip Surgeon” or “Cardiologist Perth”'
                            }
                            searchType={SEARCH_QUERY}
                            value={searchQuery || ''}
                        />
                        {displayResults && searchQuery?.length > 0 && (
                            <SearchResults
                                displayFacets={true}
                                facets={toJS(facets)}
                                promotedLocality={promotedLocality}
                                promotedProfiles={promotedProfiles}
                                promotedSpecialty={promotedSpecialty}
                                results={toJS(results)}
                                searchQuery={searchQuery}
                                searchType={SEARCH_QUERY}
                                showMissingResultButton={true}
                            />
                        )}
                        {this.context.displayPopularSpecialties && (
                            <PopularSpecialties
                                results={this.state.popularSpecialties}
                            />
                        )}
                        {displayCompleteSearchForm &&
                            this.renderLocalityFields()}
                    </>
                ) : (
                    <Button
                        action={this.toggleSearchFieldsVisibility}
                        gaSelector={SEARCH_BTN}
                        text={
                            <>
                                <Icon name="search2" />
                                {'Search'}
                            </>
                        }
                    />
                )}
            </form>
        );
    }
}
