import React from 'react';
import {observer} from 'mobx-react';

import autobind from 'common/decorators/autobind.js';
import Button from 'core/components/Button.js';
import Link from 'core/components/Link.js';
import Icon from 'core/components/Icon.js';
import {FIND_A_PROVIDER} from 'core/constants.js';
import {SearchStoreContext} from 'core/stores/RootStore.js';

export default
@observer
class MissingResultButton extends React.Component {
    static contextType = SearchStoreContext;

    constructor(props) {
        super(props);
        this.state = {
            missingPractitionerClicked: false,
        };
    }

    @autobind
    handleMissingResultLink() {
        this.setState({
            missingPractitionerClicked: true,
        });
    }

    @autobind
    handleMissingResult() {
        this.context.updateStore({
            missingResultPressed: true,
        });
    }

    render() {
        const {missingResultPressed, searchQuery} = this.context;

        return (
            <>
                {missingResultPressed ? (
                    <Link
                        action={this.handleMissingResultLink}
                        customClass={`success ${
                            this.state.missingPractitionerClicked
                                ? 'disabled'
                                : ''
                        }`.trim()}
                        isExternalLink={true}
                        href={'/contact-us'}
                    >
                        {
                            'Thanks. The search you entered has been submitted, would you like to '
                        }
                        <b>{'provide more information?'}</b>
                    </Link>
                ) : (
                    <Button
                        action={this.handleMissingResult}
                        customClass="cta"
                        text={
                            <>
                                {'Are we missing what you searched for? '}
                                <b>{'Let us know '}</b>
                                <Icon name="chevron" />
                            </>
                        }
                    />
                )}
            </>
        );
    }
}
